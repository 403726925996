<script setup lang="ts">

</script>

<template>
  <div id="footer" class="navbar flex flex-col gap-1 px-8 py-2 mt-4 border-t border-red-400">
    <div class="text-lg">
      PokeLab
      | <a class="text-pink-400" target="_blank" href="https://gala.loreheart.com">&#169; 2024 LoreHeart</a>
      | <a class="text-green-500" target="_blank" href="https://gala.loreheart.com/loriet">Development by Lore</a>
      | <a class="text-purple-500" target="_blank" href="https://gala.loreheart.com/rj">Design by RJ</a>
    </div>
    <div class="text-sm font-light italic my-0 text-red-400">
      PokeLab, Loreheart, etc is not affiliated with, sponsored or endorsed by, or in any way associated with Pokémon or The Pokémon Company International Inc.
    </div>
    <div class="text-sm font-light italic my-0 text-red-400">
      Pokémon and all respective names and images are owned by Nintendo, The Pokémon Company, or their respective owners
    </div>
  </div>
</template>

<style scoped>
  #footer {
    background-color: #880000;
    color: #ffffff;
  }

</style>
